@tailwind base;
@tailwind components;
@tailwind utilities;

 @font-face {
    font-family: acherus ;
    src: url(/public/fonts/acherus_regular.otf);
    
    font-family: poppins;
    
  } 

  body{
    z-index: 0;
}
.form-line{
    width: 91% !important;
}

  .form-error{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #FF0000;
  }
  
  .form-input {
    width: 270px;
    padding: 5px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    font-family: "Poppins", sans-serif;
  }

  .form-textarea{
    width: 100%;
    padding: 5px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    font-family: "Poppins", sans-serif;
  }

  input[type=text]:focus, input[type=date]:focus, .dropdown-select:focus {
    border: 1px solid #f1b251;
  }

  input[type=date], input[type=submit] {
    width: 270px;
    padding: 5px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    color: #ccc;
  }

  .event-image{
    filter: brightness(50%);
  }

  
.dropdown-select{
  width: 270px;
  padding: 5px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  color: #666666;
}


.background-image{
  background-image: url('/src/images/blob-transparent.png');
  background-size: 790px 620px;
  background-repeat: no-repeat;
  background-position-y: left;
  overflow: visible;
  /* margin-bottom: 20px; */
}

.event-desc-bg{
  background-image: url('/src/images/Event_Page/event_desc_bg.png');
  background-size: cover;
  height: 850px;
  background-repeat: no-repeat;
}



.swiper-button-prev{
  color: #000 !important;
  position: absolute !important;
  left: 0px !important;
}

.swiper-button-next{
  color: #000 !important;
  position: absolute !important;
  right: 0px !important;
} 

/* Event Page Style */
.event-form-label{
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}

.event_banner{
  background-image: url(/src/images/Event_Page/large_banner_image.png);
  height:853px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10px;
  padding: 20px;
 
}




@media screen and (min-width:1550px){
  .background-image{
    background-position: 30% 10% ;
    
  }
}


.card{
  font-size: 10px;
}
.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.text-fontgrey{
  font-weight: 400;
  line-height: 24px;
}
.terms_content{
  font-size: 13px;
}  
.font-bolder{
  font-weight: bolder;
}

.form-line{
  width: 91% !important;
}

@media screen and (min-width:650px){
  .testi-card{
    width: 100%;
    height: 300px;
  }
  .avatar{
    left: -105px;
  }
}

@media screen and (min-width:900px){
  .testi-card{
    height: 250px;
  }
  .avatar{
    transform: translate(125px);
  }
}

@media screen and (min-width:1200px ){
  .testi-card{
    width: 90%;
    height: 230px;
  }
  .avatar{
    transform: translate(90%);
  }
}

@media screen and (max-width:650px){
  .form-line{
    width:100% !important;
  }

  .testi-card{
    width: 100%;
    height: 260px;
  }
  .avatar{
   /* right: 20px; */
  }

  .event_banner{
    background-image: url(/src/images/Event_Page/Banner_image.png);
    height: 440px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10px;
    /* filter: brightness(70%); */
    padding: 20px;
  }
}